.footer-container {
  color: #001529;
  background-color: #f0f2f5;
  text-align: center;
  padding: 5px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
  font-size: 12px;
}

a,
a:hover {
  color: black;
}
