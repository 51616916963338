.textContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tableContainer {
  background-color: #f0f2f5;
}

.statusCountContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  top: 60px;
  margin: 20px 0px 10px 0px;
}

.statusNhistoryGrid {
  padding: 0px 30px 0px 30px;
  position: relative;
  top: 50px;
  max-height: 64vh;
  overflow-y: auto;
}
.statusNotification {
  color: #d3d3d3;
  margin-left: 5px;
  font-size: 14px;
}

.statusCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  /* margin: 0px 5px 0px 10px; */
}

.botIcon {
  /* margin: 0px 5px 0px 5px; */
  border-radius: 50%;
}
.datePicker {
  width: 130px;
}
.datePicker input {
  text-align: center;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  position: relative;
  top: 50px;
}
.historyNotification {
  font-size: 14px;
  color: rgb(211, 211, 211);
}
.input {
  flex: 1;
  margin-right: 10px;
  max-width: 20%;
}
.searchOptions {
  max-width: 200px;
  width: 100%;
  border: none;
}
.buttonGroup {
  display: flex;
  gap: 5px;
}

.optionButton {
  flex: 1;
  max-width: fit-content;
}

.customTable .ant-table-wrapper {
  background-color: #f0f2f5;
  color: #333;
}

.grayDot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 7px;
  background-color: gray;
}
.projectRowStatus {
  display: flex;
  padding: 5px;
  background-color: white;
  border: 1px solid #f0f2f5;
  justify-content: space-between;
  margin: 5px 0px 0px 0px;
  border-radius: 5px;
}
.projectRow_,
.projectRow {
  display: flex;
  padding: 5px;
  background-color: white;
  border: 1px solid #f0f2f5;
  justify-content: space-between;
  margin: 5px 0px 0px 0px;
  border-radius: 5px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #f3f3f3;
}

.login-box {
  background: white;
  padding: 25px 50px 50px 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.login-button {
  width: 255px;
  background-color: rgb(0, 133, 255);
  border-color: rgb(0, 133, 255);
  color: #ffff;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: black;
  transition: background-color 0.2s;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0px;
}

.errorMsg {
  color: rgb(175, 0, 0);
  font-size: 12px;
  font-style: italic;
  position: relative;
  bottom: 0px;
}

.userTabsContainer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 70px;
  padding: 0px 30px;
}

.gridNotFound {
  display: flex;
  justify-content: center;
  cursor: not-allowed;
  color: gray;
  position: relative;
  top: 30vh;
}

.userGrid {
  position: relative;
  top: 95px;
  max-height: 75vh;
  overflow-y: auto;
  padding: 0px 30px 0px 30px;
}

.historyStatus {
  display: flex;
  align-items: center;
}
.buttonsGroup {
  gap: 5px;
  display: flex;
}

.projectDetails {
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  gap: 5px;
}

.userErr {
  color: gray;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: not-allowed;
  top: 50vh;
}

.addstatusBtn {
  width: 50%;
}
.addstatusBtnGroup{
  display: flex;
  gap: 10px;
  padding-top: 10px;
  width: 100%;
}
  
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .datePicker {
    width: 100%;
  }
  .tableContainer {
    padding: 10px;
  }
  .statusNotification {
    padding: 5px 0px;
  }

  .historyNotification {
    text-align: center;
  }
  .statusNhistoryGrid {
    padding: 5px 5px 5px 5px;
  }
  .statusCountContainer {
    /* flex-direction: column; */
    padding: 14px 0px 0px 15px;
  }

  .statusCircle {
    width: 5px;
    height: 5px;
    margin: 5px 0;
  }

  .botIcon {
    padding: 0px 3px;
    width: 30px;
    height: 30px;
  }

  .optionsContainer {
    flex-direction: column;
    padding: 10px 10px;
  }

  .input {
    margin-bottom: 10px;
    margin-right: 0;
    max-width: 100%;
  }

  .buttonGroup {
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .searchOptions {
    max-width: 100%;
    margin: 0px 0px 5px 0px;
  }
  .optionButton {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .customTable .ant-table-wrapper {
    padding: 10px;
  }

  /* .projectRow {
    flex-direction: column;
    padding: 10px;
    margin: 0px 5px 0px 5px;
  } */
  .projectRow,
  .projectRow_ {
    /* padding: 10px; */
    margin: 10px 0;
    display: flex;
  }
  .projectRowStatus {
    margin: 10px 0;
    flex-direction: column;
    align-items: center;
  }

  .login-container {
    padding: 15px;
  }

  .addstatusBtn {
    width: 100%;
 
  }
  .addstatusBtnGroup{
    gap: 10px;
    padding-top: 10px;
    width: 100%;
    flex-direction: column;
  }

  .login-box {
    width: 90%;
    padding: 15px;
  }

  .login-button {
    width: 100%;
  }

  .errorMsg {
    font-size: 10px;
  }

  .userTabsContainer {
    flex-direction: column;
    top: 100px;

    padding: 0px 10px;
  }

  .gridNotFound {
    font-size: 14px;
  }

  .userGrid {
    top: 115px;
    padding: 0px 5px 0px 5px;
  }

  .buttonsGroup {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .projectDetails {
    flex: 1 1 30%;
    gap: 3px;
  }

  /* .userErr {
    top: 140px;
  } */
  .ant-btn {
    padding: 9px;
  }
}
