.navigation-container {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  padding: 10px 0;
  background-color: white;
  z-index: 1000; 
  position: fixed; 
  width: 100%;
  top: 0; 
  border-radius:20px
}

.logo {
  width: auto;
  height: 31px;
  padding: 0px 10px;
}

.button-group {
  display: flex;
  gap: 10px;
  padding: 0px 10px;
}

.ant-btn-primary {
  color: #1677ff;
  border: 1px solid #1677ff;
  background: white;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}



@media (max-width: 768px) {
  .navigation-container {
    flex-direction: column;
    padding: 10px 2px;
  }

  .button-group {
    gap: 0px;
    /* max-width: 90%; */
    padding: 10px 0px 0px 0px;
  }
}
