body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  font-family: Inter, sans-serif !important;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}
